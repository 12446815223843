
import { S3_BUCKET, S3_REGION } from '@/api';
import { WeddingApi } from '@/api/WeddingApi';
import {
  GENERIC_ERROR_DESCRIPTION
} from '@/constants';
import { Styles, WeddingEvent, WeddingEventImage } from 'ignite360-core';
import LightBox from 'vue-image-lightbox';
import { Component, Vue } from 'vue-property-decorator';
import UploadPublicWeddingEventImages from '@/components/bot/wedding/UploadPublicWeddingEventImages.vue';
@Component({
  name: 'public-wedding-event',
  components: {LightBox, UploadPublicWeddingEventImages},
})
export default class PublicWeddingEvent extends Vue {
  isLoading = false;

  event: WeddingEvent | null = null;

  showLightBox = false;

  filesAdded: WeddingEventImage[] = [];

  get sortedImages() {
    const allImages = [...(this.event?.images || []), ...this.filesAdded];
    return allImages.sort((a, b) => a.order - b.order);
  }

  get media() {
    return this.sortedImages.map(image => ({
      thumb: this.imageUrl(image),
      src: this.imageUrl(image),
      srcset: this.imageUrl(image),
    }));
  }


 get heroImage() {
    return this.event?.images.filter(image => image.heroImage)[0] || undefined;
  }

  get heroImageIndex() {
    return this.sortedImages.findIndex(image => image.heroImage);
  }
  handleOpenLightBox(index: number) {
    if (this.$refs.lightbox) {
    (this.$refs.lightbox as any).showImage(index)
    }
  }

  getStyle(key: keyof Styles) {
    return this.event?.styles[key];
  }

  get eventId(): string {
    return this.$route.params.id || this.$route.params.slug || '';
  }

  get botId(): string {
    return this.event?.bot.id || '';
  }

  async created() {
    console.log(this.$route);
    this.isLoading = true;
    try {
      // Create promises for both the API call and the delay
      const [eventData] = await Promise.all([
        WeddingApi.getPublicWeddingEvent(this.eventId),
        new Promise(resolve => setTimeout(resolve, 500)) // 500ms delay
      ]);
      this.event = eventData;
    } catch (e) {
      this.$notify.error({
        title: 'Loading project failed',
        description: GENERIC_ERROR_DESCRIPTION,
      });
    } finally {
      this.isLoading = false;
    }
  }

  getEventTitleSplit() {
    return this.event?.title.split(" ");
  }

    getEventDescriptionSplit() {
      return this.event?.description.split("\n");
    }


  getNames() {
    return [
      'Bree',
      'Nick',
      'Cooper',
      'and Levi',
    ]
  }

  imageUrl(image: WeddingEventImage) {
    return `https://${S3_BUCKET}.s3.${S3_REGION}.amazonaws.com/bots/${this.event!.bot.id}/wedding/events/${this.event!.id}/images/${image.id}.${image.extension}`;
  }

  handleFileUploaded(images: WeddingEventImage[]) {
    this.filesAdded.push(...images);
  }

  async deleteImage(image: WeddingEventImage) {
    this.filesAdded = this.filesAdded.filter(i => i.id !== image.id);

    try {
      await WeddingApi.deletePublicBotWeddingEventImage(this.botId, this.eventId, image.id);
    } catch (e) {
      this.$notify.error({
        title: 'Deleting image failed',
        description: GENERIC_ERROR_DESCRIPTION,
      });
    }
  }

}
