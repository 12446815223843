
import { WeddingApi } from '@/api/WeddingApi';
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';

@Component({
  components: {
    vueDropzone: vue2Dropzone
  }
})
export default class UploadPublicWeddingEventImages extends Vue {

  @Prop({ required: true, type: String })
  readonly eventId!: string;

  @Prop({ required: true, type: String })
  readonly botId!: string;


  dropzoneOptions = {
    url: 'javascript:void(0)', // Prevent auto-upload
    thumbnailWidth: 150,
    createImageThumbnails: false,
    maxFilesize: 3, // MB
    addRemoveLinks: false,
    disablePreviews: true,
    acceptedFiles: 'image/*',
    autoProcessQueue: false, // Disable auto processing
    maxFiles: 30, // Allow up to 10 files
  }

  async handleFilesAdded(files: FileList) {
    const fileArray = Array.from(files);
    const formData = new FormData();
    fileArray.forEach(file => {
      formData.append('files', file);
    });

    try {
        const response = await WeddingApi.uploadPublicImages(this.botId, this.eventId, formData);
        this.fileUploaded(fileArray, response.data);
      
      // Remove uploaded files from the dropzone
      fileArray.forEach(file => {
        (this.$refs.myDropzone as any).removeFile(file);
      });
      this.$emit('file-uploaded', response.data);
    } catch (error) {
      console.error('Error uploading files:', error);
      // Handle error (e.g., show error message to user)
    }
  }

  @Emit()
  fileUploaded(files: File[], response: any) {
    return { files, response };
  }
}
